import { Component } from '@angular/core';
import { HttpClient ,HttpHeaders} from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {
  contacts: any[] = [];


  private baseUrl = 'https://cloud.vstechhorizon.com';

  constructor(private router: Router,private http:HttpClient, private authService: AuthService) { }


  ngOnInit() {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });

    if (!this.authService.isAuthenticated()) {
      this.router.navigate(['/']);
    }
    this.http.get<any[]>(`${this.baseUrl}/contact`,{ headers: headers }).subscribe(
      data => {
        this.contacts = data;
        console.log(this.contacts); // Log the data after assignment
      },
      error => console.error('Error fetching resumes:', error)
    );
  }
}

