<header class="fixed-navbar">
  <nav>
    <div class="box-header">
      <div class="navbar">
        <img [routerLink]="['/']" src="../../assets/image/vs tech logo.png" alt="Logo" width="200"
          style="margin-left: 20px; margin-top: 5px;">
        <div class="hamburger" (click)="toggleMenu()">
          &#9776;
        </div>
        <div class="nav-links" [class.show]="isMenuOpen">
          <a class="anchor" [routerLink]="'/'">Home</a>
          <!-- <a class="anchor"(click)="scrollToSection('services')">Services</a> -->
          <div class="dropdown">
            <a class="anchor">Services</a>
            <div class="dropdown-content">
              <!-- <a [routerLink]="'/it-project'">Projects</a> -->
              <div class="nested-dropdown">
                <a>Projects</a>
                <div class="nested-dropdown-content">
                  <a [routerLink]="'/it-project'">IT</a>
                  <a [routerLink]="'/iot-project'">IOT</a>
                  <a [routerLink]="'/ai-ml-project'">AI/ML</a>
                  <a [routerLink]="'/robotic-project'">Robotics</a>
                </div>
              </div>
              <a [routerLink]="'/scholarship'">Competition</a>
              <a [routerLink]="'/steam'">STEAM</a>
              <a (click)="scrollToSection('course')">Courses</a>
              <a (click)="scrollToSection('career-guidance')">Career Guidance</a>
            </div>
          </div>
          <!-- Projects dropdown -->
          <!-- <div class="dropdown">
            <button class="dropbtn" (click)="toggleDropdown()">Projects</button>
            <a class="anchor" >Projects</a>
            <div class="dropdown-content" >
              <a [routerLink]="'/it-project'">IT</a>
              <a [routerLink]="'/iot-project'">IOT</a>
              <a [routerLink]="'/ai-ml-project'">AI/ML</a>
              <a [routerLink]="'/robotic-project'">Robotics</a>
            </div>
          </div> -->
          <!-- <a class="anchor" [routerLink]="'/scholarship'">Scholarships</a>
          <a class="anchor"[routerLink]="'/steam'">STEAM</a>
          <a class="anchor"(click)="scrollToSection('course')">Courses</a>
          <a class="anchor" [routerLink]="'/aboutus'">About</a> -->

          <!-- <a class="anchor" [routerLink]="''">Media</a> -->
          <div class="dropdown">
            <a class="anchor" [routerLink]="''">Media</a>
            <div class="dropdown-content">
              <a [routerLink]="'/gallery'">Gallery</a>
            </div>
          </div>
          <div class="dropdown">
            <a class="anchor" [routerLink]="''">Our Company</a>
            <div class="dropdown-content">
              <a [routerLink]="'/aboutus'">About Us</a>
            </div>
          </div>
          <a class="anchor" [routerLink]="'/career'">Career</a>
          <a class="anchor" [routerLink]="'/form'">Contact Us</a>
        </div>
      </div>
    </div>
  </nav>

</header>
