<!-- <div class="container">
    <header>
        <h1>Privacy Policy</h1>
        <p>This Privacy Policy describes our policies and procedures on the collection, use, and disclosure of your information when you use the services provided by VS Tech Horizon Private Limited ("Company", "We", "Us", or "Our") and informs you about your privacy rights and how the law protects you.</p>
        <p>By using our services, you agree to the collection and use of information in accordance with this Privacy Policy.</p>
    </header>

    <section id="interpretation">
        <h2>1. Interpretation and Definitions</h2>
        <p><strong>Interpretation:</strong> The words with the initial letter capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or plural.</p>
        <p><strong>Definitions:</strong></p>
        <ul>
            <li><strong>Account:</strong> means a unique account created for you to access our Service or parts of our Service.</li>
            <li><strong>Cookies:</strong> are small files that are placed on your computer, mobile device, or any other device by a website, containing the details of your browsing history on that website among its many uses.</li>
            <li><strong>Personal Data:</strong> any information that relates to an identified or identifiable individual.</li>
            <li>... (continue defining other terms as needed)</li>
        </ul>
    </section>

    <section id="collecting">
        <h2>2. Collecting and Using Your Personal Data</h2>
        <p><strong>Types of Data Collected:</strong></p>
        <p>While using Our Service, We may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to:</p>
        <ul>
            <li>Email address</li>
            <li>First name and last name</li>
            <li>Phone number</li>
            <li>Address, State, Province, ZIP/Postal code, City</li>
            <li>College/Institution Details and Location</li>
            <li>User-uploaded photos and IDs</li>
            <li>Demographic information such as postcode, preferences, and interests</li>
            <li>Any other personal information that you give us in connection while using our services</li>
        </ul>
        <p><strong>Usage Data:</strong></p>
        <p>Usage Data is collected automatically when using the Service. It may include information such as your Device's Internet Protocol address (e.g., IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers, and other diagnostic data.</p>
    </section>

    <section id="cookies">
        <h2>3. Tracking Technologies and Cookies</h2>
        <p>We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. The technologies we use may include:</p>
        <ul>
            <li><strong>Cookies or Browser Cookies:</strong> A cookie is a small file placed on your Device. You can instruct your browser to refuse all Cookies or to indicate when a cookie is being sent. However, if you do not accept Cookies, you may not be able to use some parts of our Service. Unless you have adjusted your browser setting so that it will refuse cookies, our Service may use Cookies.</li>
            <li><strong>Web Beacons:</strong> Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).</li>
            <li>... (add details on other tracking technologies used)</li>
        </ul>
    </section>

    <section id="use">
        <h2>4. Use of Your Personal Data</h2>
        <p>The Company may use Personal Data for the following purposes:</p>
        <ul>
            <li>To provide and maintain our Service, including monitoring the usage of our Service.</li>
            <li>To manage Your Account: to manage your registration as a user of the Service. The Personal Data you provide can give you access to different functionalities of the Service that are available to you as a registered user.</li>
            <li>For the performance of a contract: the development, compliance, and undertaking of the purchase contract for the products, items, or services you have purchased or of any other contract with us through the Service.</li>
            <li>... (continue listing other purposes)</li>
        </ul>
    </section>

   
    <section id="changes">
        <h2>10. Changes to this Privacy Policy</h2>
        <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
        <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
    </section>

    <footer>
        <p>If you have any questions about this Privacy Policy, you can contact us:</p>
        <ul>
            <li>By email: <a href="mailto:privacy@vstechhorizon.com">privacy@vstechhorizon.com</a></li>
        </ul>
    </footer>
</div> -->

<app-main-header></app-main-header>

<div class="container">
    <header>
        <h1>Privacy Policy Information</h1>
       <br>
        <p><strong>Last updated: July 13, 2024</strong></p>
        <p>At VS Tech Horizon Private Limited ("we," "our," or "us"), we are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, share, and protect your personal information when you interact with our services, website, or programs. By using our website or services, you agree to the practices outlined in this Privacy Policy.</p>
        <p>By using our services, you agree to the collection and use of information in accordance with this Privacy Policy.</p>
    </header>

    <section id="interpretation">
        <h2>1. Information We Collect</h2>
        <h3>We may collect the following types of information from you:</h3>
        <!-- <p><strong>Interpretation:</strong> The words with the initial letter capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or plural.</p>
        <p><strong>Definitions</strong></p> -->
        <p><strong>Personal Information</strong></p>
        <ul>
            <li><b>Contact Details:</b> Name, email address, phone number, and mailing address.</li>
            <li><b>Educational Information:</b> Information about your academic background if applying for scholarships, internships, or other programs.</li>
            <li><strong>Payment Information:</strong> Billing information such as your bank account details or credit card details.</li>
            <li><strong>Professional Information:</strong> Work experience, resume, and other information related to job applications or professional inquiries.</li>
            <li><strong>Personal Data:</strong> Any information that relates to an identified or identifiable individual.</li>
        </ul>
    </section>

    <section id="collecting">
        <!-- <h2>2. Collecting and Using Your Personal Data</h2>
        <p><strong>Types of Data Collected:</strong></p> -->
       <p><strong>Automatically Collected Information:</strong></p>
       <ul>
            <li><b>Device Information:</b> Name, email address, phone number, and mailing address.</li>
            <li><b>Usage Information:</b>  Pages visited on our website, time spent on the website, and other interaction data.</li>
            <li><strong>Cookies and Tracking Technologies:</strong> Information collected through cookies or similar technologies for better user experience and website analytics.</li>
        </ul>
    </section>

    <section id="cookies">
        <h2>2. How We Use Your Information</h2>
        <p>We may use your information for the following purposes:</p>
        <ul>
            <li><strong>Service Delivery:</strong> To provide our services, including scholarship programs, internships, career guidance, and STEAM Connect courses.</li>
            <li><strong>Communication:</strong> To contact you regarding your participation in our programs or respond to your inquiries.</li>
            <li><strong>Marketing:</strong> To send you promotional materials, newsletters, or information about new courses and events (you can opt out at any time).</li>
            <li><strong>Analytics and Improvements:</strong> To analyze website performance, improve user experience, and develop new products or services.
            </li>
            <li><strong>Legal Compliance:</strong> To comply with legal obligations and protect our rights.
            </li>
        </ul>
    </section>
    <!-- <strong></strong>  -->
    <section id="use">
        <h2>3. How We Share Your Information</h2>
        <p>We may share your personal information with the following parties:</p>
        <ul>
            <li><strong>Service Providers:</strong> Third-party vendors who assist us with payment processing, analytics, marketing, and IT services.</li>
            <li><strong>Partner Organizations:</strong> Schools, colleges, and educational bodies with which we partner for programs and initiatives.</li>
            <li><strong>Legal Authorities:</strong>  If required by law or in response to valid legal requests, such as subpoenas or court orders.</li>
            <li><strong>Analytics and Improvements:</strong> To analyze website performance, improve user experience, and develop new products or services.
            </li>
            <li><strong>Business Transfers:</strong>  If we merge with or are acquired by another company, your information may be transferred to the new entity.
            </li>
        </ul>
    </section>

    <section id="sharing">
        <h2>4. Data Security</h2>
        <p>We take reasonable measures to protect your personal information from unauthorized access, misuse, alteration, or destruction. However, no security system is entirely foolproof, and we cannot guarantee absolute security.</p>
    </section>

    <section id="retention">
        <h2>5. Your Rights</h2>
        <p>You have the following rights concerning your personal information:</p>
        <ul>
            <li><strong>Access:</strong> You may request access to the personal information we hold about you.</li>
            <li><strong>Correction:</strong> You may request corrections to your personal information if it is inaccurate or incomplete.</li>
            <li><strong>Deletion:</strong>  You may request that we delete your personal information, subject to certain exceptions.</li>
            <li><strong>Opt-Out:</strong> You can opt out of receiving marketing communications from us at any time.
            </li>
        </ul>


    </section>

    <section id="security">
        <h2>6. Data Retention</h2>
        <p>We retain your personal information for as long as necessary to fulfill the purposes for which it was collected or as required by law.</p>
    </section>

    <section id="children">
        <h2>7. Children's Privacy</h2>
        <p>We do not knowingly collect personal information from individuals under the age of 18 without parental consent. If we discover that we have unintentionally collected such information, we will delete it promptly.</p>
    </section>

    <section id="links">
        <h2>8. Third-Party Links</h2>
        <p>Our website may contain links to third-party websites. We are not responsible for the privacy practices of these third-party sites. We encourage you to read their privacy policies before providing any personal information.</p>
    </section>

    <section id="changes">
        <h2>9. Changes to This Privacy Policy</h2>
        <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. Your continued use of our services following such changes constitutes your acceptance of the revised Privacy Policy.</p>
    </section>
    <section id="changes">
        <h2>10. Contact Us</h2>
        <p>If you have any questions or concerns about this Privacy Policy or how we handle your personal information, please contact us at:</p>
        <ul>
            <li>By email: <a href="mailto:vstechhorizon@gmail.com">vstechhorizon@gmail.com</a></li>
        </ul>
    </section>
</div>

<app-footer></app-footer>