<!-- 
 <app-main-header [class.disabled]="isPopDisabled"></app-main-header>
 <div class="main" (click)="hidePopup()">
   <div class="popup" *ngIf="showPopup">
     <video src="../../assets/img/Scholership exam poster1.mp4" (click)="hidePopup()" autoplay loop></video>
   </div>
   <div class="container" [class.disabled]="isPopDisabled">
     <h1>Exams Details</h1>
     <p> <b>Secure your future with the VS Tech Horizon Scholarship Exam! Win up to ₹100,000, earn certificates, receive lifetime career guidance, and Students with >75%: Scholar Access with career guidance and enhanced resources. Register now!</b>
      <br>
      <br>
       <b>Contact Information for Assistance: </b>
        <br>
        <br>
       If you encounter any difficulties or have questions while filling out the form, please feel free to reach out to us for assistance: <br>
       <br>
       <b>. Name: </b>VS Tech Horizon Scholarship Support Team <br>
       <b>. Email:</b> vstechhorizonscholarship@gmail.com <br>
       <b>. Phone Number:</b> +91 9325360357 <br>
       <b>. Availability:</b> 8 AM to 10 PM (All Day) <br>
       <br>
       We are here to help ensure a smooth registration process. Please don't hesitate to contact us if you need support .<br>
     </p>
     <button id="btn" class="btn btn-primary" [routerLink]="'/scholarship-form'">Apply Now</button>
   </div>
 </div>
 <app-chatbot [class.disabled]="isPopDisabled"></app-chatbot>
<app-whatsapp [class.disabled]="isPopDisabled"></app-whatsapp>
<app-footer [class.disabled]="isPopDisabled"></app-footer> -->



<!-- previous code is working code for scholarship exam currently there is no scholarship exam so team is working on below code -->

<app-main-header [class.disabled]="isPopDisabled"></app-main-header>
 <div class="main" (click)="hidePopup()">
   <div class="popup" *ngIf="showPopup">
     <video src="../../assets/Roborush.mp4" (click)="hidePopup()" autoplay loop></video>
   </div>
   <div class="container" [class.disabled]="isPopDisabled">
     <h1>Join the RoboRush STEM Fest 2024-2025!</h1>
     <p> <b>We are excited to announce that registrations for the RoboRush STEM Fest 2024-25 are now open! This competition is your chance to dive into the exciting world of STEM (Science, Technology, Engineering, and Mathematics) through hands-on challenges and innovation-driven competitions.</b>
      <br>
      <br>
       <b>What’s RoboRush STEM Fest?</b>
        <br>
        A thrilling competition for students from Grades 1 to 10 to explore STEM through interactive activities, robotics, and real-world problem-solving.
        <br>
        What Awaits You: <br>
       <br>
       <b>. </b>School-Level MCQ Exam: Test your STEM knowledge.<br>
       <b>. </b>Intra-School Robotics Challenge: Build and race your own robot.<br>
       <b>. </b>Innovative Idea Competition: Showcase creative solutions.<br>
       <br>
       <b>Exciting Rewards:</b><br>
       <b>. </b>Robotics Kits, Prize Money, and Live Robotics Course for winners.<br>
       <b>. </b>Recorded Course Access and Certificates for all participants.<br>
       <br>
       <b>Key Dates:</b><br>
       <b>. </b>Registration is Open Now!<br>
       <b>. </b>Innovative Idea Submission: 20th - 22nd December 2024<br>
       <b>. </b>Intra-School Competition: 3rd January 2025<br>
       <br>
       <b>For More Details: </b>
       <b>Contact us at +91 9325360357 or support@vstechhorizon.com</b>
     </p>
     <!-- <button id="btn" class="btn btn-primary" [routerLink]="'/scholarship-form'">Apply Now</button> -->
   </div>
 </div>
 <app-chatbot [class.disabled]="isPopDisabled"></app-chatbot>
<app-whatsapp [class.disabled]="isPopDisabled"></app-whatsapp>
<app-footer [class.disabled]="isPopDisabled"></app-footer>