import { Component } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
@Component({
  selector: 'app-student',
  templateUrl: './student.component.html',
  styleUrls: ['./student.component.scss']
})
export class StudentComponent {
  students : any[]=[] ;

  private baseUrl = 'https://cloud.vstechhorizon.com';
  constructor(private router: Router, private http:HttpClient, private authService: AuthService) {}

  ngOnInit() {
    if (!this.authService.isAuthenticated()) {
      this.router.navigate(['/']);
    }

    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });

    this.http.get<any[]>(`${this.baseUrl}/applications`,{ headers: headers }).subscribe(
      data => {
        this.students = data;
        console.log(this.students); // Log the data after assignment
      },
      error => console.error('Error fetching resumes:', error)
    );
  }

}
