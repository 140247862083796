// import { Component } from '@angular/core';

// @Component({
//   selector: 'app-gallery',
//   templateUrl: './gallery.component.html',
//   styleUrls: ['./gallery.component.scss']
// })
// export class GalleryComponent {

// }


import { Component } from '@angular/core';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent {
  images: string[] = [
    '../../assets/img/gallery1.jpg',
    '../../assets/img/gallery4.jpg',
    '../../assets/img/gallery6.jpg',
    '../../assets/img/gallery7.jpg',
    '../../assets/img/gallery8.jpg',
    '../../assets/img/gallery9.jpg',
    '../../assets/img/gallery2.jpg',
    '../../assets/img/gallery3.jpg',
    '../../assets/img/gallery5.jpg',
    '../../assets/image/Vishnuji Shekuji Satav High School & Junior College.jpg',
    '../../assets/image/ACS.jpg',
    '../../assets/image/ACS1.jpg',
  ];

  imageRows: string[][] = []; 
  selectedImage: string | null = null;
  currentIndex: number = 0;

  constructor() {
    this.splitImagesIntoRows();
  }

  splitImagesIntoRows() {
    for (let i = 0; i < this.images.length; i += 3) {
      this.imageRows.push(this.images.slice(i, i + 3));
    }
  }

  openImage(image: string) {
    this.selectedImage = image;
    this.currentIndex = this.images.indexOf(image);
  }

  closeImage() {
    this.selectedImage = null;
  }

  prevImage() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.selectedImage = this.images[this.currentIndex];
    }
  }

  nextImage() {
    if (this.currentIndex < this.images.length - 1) {
      this.currentIndex++;
      this.selectedImage = this.images[this.currentIndex];
    }
  }
}