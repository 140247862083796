import { Component, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { OnInit } from '@angular/core';
import { PaymentService } from '../payment.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../data.service';
import { HttpClient } from '@angular/common/http';
declare var Razorpay: any;
@Component({
  selector: 'app-career-guidance-form',
  templateUrl: './career-guidance-form.component.html',
  styleUrls: ['./career-guidance-form.component.scss']
})
export class CareerGuidanceFormComponent implements OnInit{






//   showPaymentSection: boolean = false;
//   careerForm: FormGroup;
// amount!: number;
//   constructor(
//     private formBuilder: FormBuilder,
//     private route: ActivatedRoute
//   ) {
//     this.careerForm = this.formBuilder.group({
//       fullName: ['', Validators.required],
//       dob: ['', Validators.required],
//       mobileNumber: ['', [Validators.required, Validators.pattern('^\\d{10}$')]],
//       email: ['', [Validators.required, Validators.email]],
//       gender: ['', Validators.required],
//       discussionPoint: ['', Validators.required],
//       amount: ['', Validators.required] // Add amount field with required validation
//     });
//   }
careerForm: FormGroup;
amount:any;
showPaymentSection: boolean = false;

constructor(private fb: FormBuilder, private paymentService: PaymentService,private route: ActivatedRoute ,private http:HttpClient, private routers:Router,private dataService: DataService) {
  this.careerForm = this.fb.group({
    fullName: ['', Validators.required],
    dob: ['', Validators.required],
    mobileNumber: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
    email: ['', [Validators.required, Validators.email]],
    gender: ['', Validators.required],
    discussionPoint: [''],
    paymentId:['']
  });
}
  ngOnInit(): void {
    const navigation = this.routers.getCurrentNavigation();
    if (navigation?.extras.state?.['amount']) {
      this.amount = navigation.extras.state['amount'];
    } else {
      // Fall back to service if no amount in navigation state
      this.amount = this.dataService.getAmount();
    }
    if(!this.amount){
      window.alert("Select Valid Plan")
    }
    // this.route.paramMap.subscribe(params => {
    //   const amountParam = params.get('amount');
    //   this.amount = amountParam ? +amountParam : null;
    //   if (this.amount === null) {
    //     window.alert("null amout")
    //     // this.routers.navigate(['/error']); // Redirect if no amount is set
    //   }
    // });
    // this.amount = this.dataService.getAmount();
    // console.log(this.amount)
    // if(!this.amount){
    //   window.alert("Select Valid Plan")
    // }
    // const navigation = this.routers.getCurrentNavigation();
    // if (navigation?.extras.state) {
    //   this.amount = navigation.extras.state['amount'];
    //   console.log(this.amount)
    // }
    // this.route.queryParams.subscribe(params => {
    //   const amountParam = params['amount'];
    //   this.amount=amountParam;
    //   if (amountParam) {
    //     this.careerForm.patchValue({ amount: amountParam }); // Set amount from query parameter
    //   }
    // });

  }

//   saveForm() {
//     // Logic to save form data temporarily or to local storage
//     console.log('Form data saved:', this.careerForm.value);
//   }

//   nextStep() {
//     if (this.careerForm.valid) {
//       this.showPaymentSection = true;
//     }
//   }

//   saveAndNext() {
//     if (this.careerForm.valid) {
//       this.showPaymentSection = true;
//     }
//   }



//   onSubmit() {
//     if (this.careerForm.valid) {
//       console.log(this.careerForm.value);
//       // Add submission logic here
//     }
//   }



//   private markFormGroupTouched(formGroup: FormGroup) {
//     Object.values(formGroup.controls).forEach(control => {
//       control.markAsTouched();
//       if (control instanceof FormGroup) {
//         this.markFormGroupTouched(control);
//       }
//     });
//   }



//   payNow() {
//     const RozarpayOptions = {
//       description: 'Sample Razorpay demo',
//       currency: 'INR',
//       amount: this.amount*100,
//       // name: 'VS Tech Horizon',
//       key: 'rzp_test_P6P49tf2uSj2ku',
//       // key: 'rzp_test_L4KYt4E6LiVdOhg',
//       image: '../../assets/img/VSTechHorizonLogo.jpg',
//       prefill: {
//         // name: this.registrationData1.name ,
//         // email: this.registrationData1.email,
//         // phone: this.registrationData1.number
//       },
//       theme: {
//         color: '#007BFF;'
//       },
//       modal: {
//         ondismiss:  () => {
//           console.log('dismissed')
//         }
//       }
//     }

//     const successCallback = (response: any) => {
//       console.log(response);

//       // // Automatically set the transaction ID from the Razorpay response
//       // this.transactionId = response.razorpay_payment_id;

//       // // You may also want to submit the form automatically
//       // this.submitPayment();
//     };

//     const failureCallback = (e: any) => {
//       console.log(e);
//     }

//     Razorpay.open(RozarpayOptions,successCallback, failureCallback)
//   }



  saveForm() {
    if (this.careerForm.valid) {
      console.log('Form Saved', this.careerForm.value);
      this.http.post("https://cloud.vstechhorizon.com/careerguidance",this.careerForm.value).subscribe(
      (Response)=>{
        window.alert("Form Sucessfully Submited");
        this.careerForm.reset();
        this.routers.navigateByUrl("/");
      },(error)=>{
        window.alert("Failed to Submit Form");
      }
      )

    } else {
      console.log('Form is not valid');
    }
  }

  nextStep() {
    this.showPaymentSection = true;
  }

  pay() {
    // const amount = 1; // Amount in INR
    const currency = 'INR';

    this.paymentService.createOrder(this.amount, currency).subscribe(
      (order:any) => {
        this.paymentService.initiatePayment(order.id, this.amount, currency,this.careerForm.value.fullName,this.careerForm.value.email,this.careerForm.value.mobileNumber, this.paymentResponseHandler.bind(this));
      },
      (error:any) => {
        console.error('Order creation failed', error);
      }
    );
  }

  paymentResponseHandler(response: any) {

    const transactionId = response.transactionId; // Example key for Transaction ID
    console.log(`Payment successful with Transaction ID: ${transactionId}`);
    //   console.log('Payment successful', response);
    console.log('Payment successful', response);
    this.careerForm.value.paymentId=response.razorpay_payment_id;
    const paymentId = response.razorpay_payment_id;
    const orderId = response.razorpay_order_id;
    const signature = response.razorpay_signature;

    console.log('Payment ID:', paymentId);
    console.log('Order ID:', orderId);
    console.log('Signature:', signature);

    window.alert("Payment Succesfully Done ");
    this.saveForm();

    // Handle the response, such as updating the order status in your backend
  }
}


