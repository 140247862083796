import { Component, OnInit, ViewChild } from '@angular/core';

// import { VideoModalComponent } from './video-modal/video-modal.componen';
// import { VideoModalComponent } from '../video-modal/video-modal.component';
import { Router } from '@angular/router';
@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss']
})
export class CoursesComponent implements OnInit {
  // @ViewChild('videoModal') videoModal!: VideoModalComponent;

  constructor(private router: Router) { }
  courses = [
    {
      title: 'C',
      imgSrc: '../../assets/img/C.png',
      enrolledLearners: 200 + " Enrolled Learners",
      schedule: 'Weekend/Weekdays',
      mode: 'Live Class',
      rating: 5,
      showInfo: false,
      videoUrl: 'https://www.youtube.com/embed/dQw4w9WgXcQ',
      content: 'Sed tempor magna sed dui posuere, sed vestibulum nunc fermentum. Nulla facilisi.'

    },
    {
      title: 'C++',
      imgSrc: '../../assets/img/c++.png',
      enrolledLearners: 150 + " Enrolled Learners",
      schedule: 'Weekend/Weekdays',
      mode: 'Live Class',
      rating: 4,
      showInfo: false,
      videoUrl: 'https://www.youtube.com/embed/dQw4w9WgXcQ',
      content: 'Sed tempor magna sed dui posuere, sed vestibulum nunc fermentum. Nulla facilisi.'

    },
    {
      title: 'Python',
      imgSrc: '../../assets/img/Python.jpg',
      enrolledLearners: 300 + " Enrolled Learners",
      schedule: 'Weekend/Weekdays',
      mode: 'Live Class',
      rating: 5,
      showInfo: false,
      videoUrl: 'https://www.youtube.com/embed/dQw4w9WgXcQ',
      content: 'Sed tempor magna sed dui posuere, sed vestibulum nunc fermentum. Nulla facilisi.'

    },
    {
      title: 'AI',
      imgSrc: '../../assets/img/ai.png',
      enrolledLearners: 250 + " Enrolled Learners",
      schedule: 'Weekend/Weekdays',
      mode: 'Live Class',
      rating: 4,
      showInfo: false,
      videoUrl: 'https://www.youtube.com/embed/dQw4w9WgXcQ',
      content: 'Sed tempor magna sed dui posuere, sed vestibulum nunc fermentum. Nulla facilisi.'

    },
    {
      title: 'Data Structure',
      imgSrc: '../../assets/img/data.png',
      enrolledLearners: 180 + " Enrolled Learners",
      schedule: 'Weekend/Weekdays',
      mode: 'Live Class',
      rating: 5,
      showInfo: false,
      videoUrl: 'https://www.youtube.com/embed/dQw4w9WgXcQ',
      content: 'Sed tempor magna sed dui posuere, sed vestibulum nunc fermentum. Nulla facilisi.'

    },
    {
      title: 'Cloud Computing',
      imgSrc: '../../assets/img/cloud computing image.jpg',
      enrolledLearners: 220 + " Enrolled Learners",
      schedule: 'Weekend/Weekdays',
      mode: 'Live Class',
      rating: 5,
      showInfo: false,
      videoUrl: 'https://www.youtube.com/embed/dQw4w9WgXcQ',
      content: 'Sed tempor magna sed dui posuere, sed vestibulum nunc fermentum. Nulla facilisi.'

    },
    {
      title: 'DevOps',
      imgSrc: '../../assets/img/devops1.jpg',
      enrolledLearners: 190 + " Enrolled Learners",
      schedule: 'Weekend/Weekdays',
      mode: 'Live Class',
      rating: 5,
      showInfo: false,
      videoUrl: 'https://www.youtube.com/embed/dQw4w9WgXcQ',
      content: 'Sed tempor magna sed dui posuere, sed vestibulum nunc fermentum. Nulla facilisi.'

    },
    {
      title: 'Cyber Security',
      imgSrc: '../../assets/img/cyber.png',
      enrolledLearners: 120 + " Enrolled Learners",
      schedule: 'Weekend/Weekdays',
      mode: 'Live Class',
      rating: 5,
      showInfo: false,
      videoUrl: 'https://www.youtube.com/embed/dQw4w9WgXcQ',
      content: 'Sed tempor magna sed dui posuere, sed vestibulum nunc fermentum. Nulla facilisi.'

    },
    {
      title: 'Software Testing',
      imgSrc: '../../assets/img/software-testing.jpg',
      enrolledLearners: 270 + " Enrolled Learners",
      schedule: 'Weekend/Weekdays',
      mode: 'Live Class',
      rating: 5,
      showInfo: false,
      videoUrl: 'https://www.youtube.com/embed/dQw4w9WgXcQ',
      content: 'Sed tempor magna sed dui posuere, sed vestibulum nunc fermentum. Nulla facilisi.'

    },
    {
      title: 'Frontend Devlopment',
      imgSrc: '../../assets/img/front.jfif',
      enrolledLearners: 520 + " Enrolled Learners",
      schedule: 'Weekend/Weekdays',
      mode: 'Live Class',
      rating: 4.6,
      showInfo: false,
      videoUrl: 'https://www.youtube.com/embed/dQw4w9WgXcQ',
      content: 'Sed tempor magna sed dui posuere, sed vestibulum nunc fermentum. Nulla facilisi.'

    },
    {
      title: 'Block Chain',
      imgSrc: '../../assets/img/block.jpg',
      enrolledLearners: 154 + " Enrolled Learners",
      schedule: 'Weekend/Weekdays',
      mode: 'Live Class',
      rating: 4.5,
      showInfo: false,
      videoUrl: 'https://www.youtube.com/embed/dQw4w9WgXcQ',
      content: 'Sed tempor magna sed dui posuere, sed vestibulum nunc fermentum. Nulla facilisi.'

    },
    {
      title: 'IOS App Development',
      imgSrc: '../../assets/img/ios.jpg',
      enrolledLearners: 340 + " Enrolled Learners",
      schedule: 'Weekend/Weekdays',
      mode: 'Live Class',
      rating: 5,
      showInfo: false,
      videoUrl: 'https://www.youtube.com/embed/dQw4w9WgXcQ',
      content: 'Sed tempor magna sed dui posuere, sed vestibulum nunc fermentum. Nulla facilisi.'

    },
    {
      title: 'Robotics & IoT',
      imgSrc: '../../assets/img/iot.jpg',
      enrolledLearners: 110 + " Enrolled Learners",
      schedule: 'Weekend/Weekdays',
      mode: 'Live Class',
      rating: 5,
      showInfo: false,
      videoUrl: 'https://www.youtube.com/embed/dQw4w9WgXcQ',
      content: 'Sed tempor magna sed dui posuere, sed vestibulum nunc fermentum. Nulla facilisi.'

    },
  ];


  toggleInfo(course: any) {
    course.showInfo = !course.showInfo;
  }

  // openVideo(course: any) {
  //   this.videoModal.course = course;
  //   this.videoModal.openModal();
  // }
  // openVideo(course: any) {
  //   this.videoModal.course = course;
  //   console.log(course + "couse information")
  //   this.videoModal.openModal();

  // }
  // toggleInfo(course: any) {
  //   course.showInfo = !course.showInfo; // Toggle show/hide for the info popup
  // }


  getStarRating(rating: number): boolean[] {
    const starList = [];
    for (let i = 1; i <= 5; i++) {
      starList.push(i <= rating);
    }
    return starList;
  }
  showPopup = false;
  name = '';
  message = '';

  ngOnInit() {

    // this.showPopupBox();
  }

  showPopupBox() {
    this.showPopup = true;
    setTimeout(() => {
      const popupElement = document.querySelector('.popup');
      if (popupElement) {
        popupElement.classList.add('show');
      }
    }, 15);
  }

  hidePopup() {
    const popupElement = document.querySelector('.popup');
    if (popupElement) {
      popupElement.classList.remove('show');
    }
    setTimeout(() => {
      this.showPopup = false;
    }, 500);
  }




  navigateToCourse(courseTitle: string) {
    switch (courseTitle) {
      case 'C':
        this.router.navigate(['/sub-c']);
        break;
      case 'C++':
        this.router.navigate(['/sub-cpp']);
        break;
      case 'Python':
        this.router.navigate(['/sub-python']);
        break;
      case 'AI':
        this.router.navigate(['/sub-ai']);
        break;
      case 'Data Structure':
        this.router.navigate(['/sub-datastructure']);
        break;
      case 'Cloud Computing':
        this.router.navigate(['/sub-cloud']);
        break;
      case 'DevOps':
        this.router.navigate(['/sub-devops']);
        break;
      case 'Cyber Security':
        this.router.navigate(['/sub-cyber']);
        break;
      case 'Software Testing':
        this.router.navigate(['/sub-testing']);
        break;
      case 'Frontend Devlopment':
        this.router.navigate(['/sub-frontend']);
        break;
      case 'Block Chain':
        this.router.navigate(['/sub-block']);
        break;
      case 'IOS App Development':
        this.router.navigate(['/sub-ios']);
        break;
      case 'Robotics & IoT':
        this.router.navigate(['/sub-iotrobotic']);
        break;
      default:
        break;
    }
  }
}