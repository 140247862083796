<app-main-header></app-main-header>\

<div style="padding-top:2%;" class="main">
    <h1>Gallery</h1>
    <div class="image-gallery">
        <div class="row" *ngFor="let row of imageRows">
            <div class="col" *ngFor="let image of row">
                <img [src]="image" alt="Image" class="img-thumbnail"  (click)="openImage(image)">
            </div>
        </div>
    </div>
</div>



<div class="image-modal" *ngIf="selectedImage">
    <span class="close" (click)="closeImage()">&times;</span>
    <div class="counter">{{ currentIndex + 1 }}/{{ images.length }}</div>
    <img [src]="selectedImage" alt="Selected Image" class="full-size-img">
    <button class="nav-button prev" (click)="prevImage()">&#10094;</button>
    <button class="nav-button next" (click)="nextImage()">&#10095;</button>
</div>
<app-chatbot></app-chatbot>
<app-whatsapp></app-whatsapp>
<app-footer></app-footer>