<app-main-header></app-main-header>
<div id="main-container">
    
    <h1>Terms and Conditions</h1>

    <p><strong>Last updated: July 13, 2024</strong></p>
    
    <p>These Terms and Conditions ("Terms") govern your use of the website, services, and programs provided by VS Tech Horizon Private Limited ("we," "our," or "us"). By accessing or using our website, services, or participating in our programs, you agree to these Terms. If you do not agree to these Terms, you may not use our services.</p>
    
    <h2>1. Acceptance of Terms</h2>
    
    <p>By using our services, you acknowledge that you have read, understood, and agree to be bound by these Terms, including any additional terms and policies referenced herein.</p>
    
    <h2>2. Services Offered</h2>
    
    <p>VS Tech Horizon offers educational services, career guidance, scholarship programs, internships, STEAM Connect programs, and more. These services may be subject to additional terms, guidelines, or rules, which will be presented to you when you sign up for or participate in the specific service.</p>
    
    <h2>3. Eligibility</h2>
    <h4>To use our services:</h4>
    
    <ul>
        <li>You must be at least 18 years old or have parental or guardian consent if you are a minor.</li>
        <li>You must provide accurate and truthful information during registration or participation in our programs.</li>
        <li>You agree not to use our services for any illegal or unauthorized purposes.</li>
    </ul>
    
    <h2>4. User Responsibilities</h2>
    <h4>As a user of our services, you agree to:</h4>
    <ul>
        <li>Follow all applicable laws and regulations.</li>
        <li>Respect intellectual property rights, including not distributing or reproducing content without permission.</li>
        <li>Provide accurate and complete information when interacting with our services.</li>
        <li>Not engage in disruptive or harmful activities, including hacking, transmitting malware, or abusing our platform or other users.</li>
    </ul>
    
    
    <h2>5. Registration and Account Security</h2>
    
    <p>Some of our services may require you to create an account. You are responsible for maintaining the confidentiality of your account credentials and for any activities conducted under your account. If you suspect any unauthorized use of your account, you must notify us immediately.</p>
    
    <h2>6. Fees and Payments</h2>
    
    <p>Certain services, such as our courses or events, may require payment. By making a payment, you agree to the following terms:</p>
    <ul>
        <li>All fees must be paid in the currency specified.</li>
        <li>Payments are due at the time of registration unless otherwise specified.</li>
        <li>We reserve the right to change our fees for future services, but these changes will not affect any ongoing services for which you have already paid.</li>
    </ul>
    
    <h2>7. Refunds and Cancellations</h2>
    
    <ul>
        <li><strong>Courses and Programs: </strong> You may cancel your participation in a course or program by notifying us prior to the start date. Refund policies will be specified for each course or event.</li>
        <li><strong>Scholarship and Internship Programs: </strong> Fees related to applications for scholarships or internships are non-refundable unless explicitly stated.</li>
    </ul>


    <h2>8. Intellectual Property</h2>
    
    <p>All content provided on our website and through our services, including but not limited to text, images, videos, software, logos, and trademarks, are the property of VS Tech Horizon or our licensors. You may not use, copy, modify, or distribute this content without our prior written consent.</p>
    
    <h2>9. Termination</h2>
    
    <p>We reserve the right to terminate or suspend your access to our services, at our sole discretion, without prior notice, for conduct that we believe violates these Terms or any other policies. Upon termination, your right to use our services will cease immediately.</p>
    
    <h2>10. Disclaimer of Warranties</h2>
    
    <p>Our services are provided on an "as is" and "as available" basis. We do not warrant that:</p>
    <ul>
        <li>The services will meet your requirements.</li>
        <li>The services will be uninterrupted, secure, or error-free.</li>
        <li>Any content or materials obtained through our services will be accurate or reliable.</li>
    </ul>
    <p>To the fullest extent permitted by law, we disclaim all warranties, whether express or implied, regarding the services.</p>
    
    <h2>11. Limitation of Liability</h2>
    <p>To the extent permitted by law, VS Tech Horizon shall not be liable for any indirect, incidental, special, or consequential damages, including loss of profits, data, or goodwill, arising from your use of our services. Our total liability for any claims related to the services will not exceed the amount you paid us, if applicable, for the specific service giving rise to the claim.</p>
    
    <h2>12. Indemnification</h2>
    <p>You agree to indemnify and hold VS Tech Horizon, its directors, employees, and partners harmless from any claims, damages, losses, liabilities, and expenses (including legal fees) arising from your use of our services or violation of these Terms.</p>
    
    <h2>13. Third-Party Links</h2>
    <p>Our services may include links to third-party websites or services. We are not responsible for the content, privacy practices, or any transactions that occur on third-party websites. You access third-party websites at your own risk.</p>

    <h2>14. Changes to the Terms</h2>
    <p>We may update these Terms from time to time. Any changes will be posted on this page, and your continued use of our services after such changes constitutes your acceptance of the revised Terms.</p>

    <h2>15. Governing Law and Dispute Resolution</h2>
    <p>We may update these Terms from time to time. Any changes will be posted on this page, and your continued use of our services after such changes constitutes your acceptance of the revised Terms.</p>

    <h2>16. Copyright Policy</h2>
    <ul>
        <li>All original content by VS Tech Horizon Private Limited is owned by the company and protected by copyright laws.</li>
        <li>Users may only use content for personal, educational, and non-commercial purposes.</li>
        <li>Unauthorized reproduction, distribution, or modification of content is prohibited.</li>
        <li>Third-party content belongs to respective owners and requires permission for use.</li>
        <li>For copyright inquiries or to report violations, contact us at <a href="mailto:vstechhorizon@gmail.com">vstechhorizon@gmail.com</a>.</li>
    </ul>

    <h2>17. Contact Information</h2>
    
    <p>For inquiries, feedback, comments, or complaints, please contact us at:</p>
    <!-- <ul>
        <li>Email: [Your contact email address]</li>
        <li>Website: [Your website contact page URL]</li>
        <li>Phone: [Your contact phone number]</li>
        
    </ul> -->
    <p>VS Tech Horizon Private Limited,<br>
        Plot No.13 Gat No.574 Sam Fransisco Park, Matosh, Haveli, Kesnand Pune-412207 <br>
        Contact Number:+91 9325360357 <br>
        Email: vstechhorizon@gmail.com</p> <br>
    
</div>

<app-footer></app-footer>