import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-career-guidance',
  templateUrl: './career-guidance.component.html',
  styleUrls: ['./career-guidance.component.scss']
})
export class CareerGuidanceComponent {

  careerForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient
  ) {
    this.careerForm = this.formBuilder.group({
      fullName: ['', Validators.required],
      dob: ['', Validators.required],
      mobileNumber: ['', [Validators.required, Validators.pattern('^[0-9]{10}$')]],
      email: ['', [Validators.required, Validators.email]],
      gender: ['', Validators.required],
      discussionPoint: ['', Validators.required]
    });
  }

  onSubmit() {
    if (this.careerForm.valid) {
      console.log('Form submitted:', this.careerForm.value);
      this.http.post("https://cloud.vstechhorizon.com/careerguidance", this.careerForm.value).subscribe(
        (response) => {
          console.log('Response:', response);
          this.resetForm();
          window.alert("Form submitted successfully!");
        },
        (error) => {
          console.error('Error:', error);
          window.alert("Failed to submit form. Please try again later.");
        }
      );
    } else {
      console.log('Form is invalid');
      this.markFormGroupTouched(this.careerForm);
    }
  }

  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }

  resetForm() {
    this.careerForm.reset({
      fullName: '',
      dob: '',
      mobileNumber: '',
      email: '',
      gender: '',
      discussionPoint: ''
    });
  }
}
