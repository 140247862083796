
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormService } from '../form.service';
import { Router } from '@angular/router';
import { PaymentService } from '../payment.service';
declare var Razorpay: any;
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  amount: string = '10.00';
  transactionId: string = '';
  // screenshot: File | null = null;
  formValid: boolean = false;
  upiId: string = 'abcd1457df';
  registrationData1:any;
  constructor(private http: HttpClient, private formService: FormService, private router:Router,private paymentService:PaymentService) { }

  ngOnInit(): void {
    this.clearFormOnReload();
    this.registrationData1 = this.formService.getRegistrationData();
  }

  clearFormOnReload(): void {
    console.log("inside clear form")
    const form = document.getElementById('myForm') as HTMLFormElement;
    if (form) {
      form.reset();
    }
  }

  checkFormValidity(): void {
    this.formValid = this.transactionId !== ''
  }

  // onFileChange(event: any): void {
  //   if (event.target.files.length > 0) {
  //     this.screenshot = event.target.files[0];
  //     this.checkFormValidity();
  //   }
  // }

  submitPayment(): void {
    const formData = new FormData();
    formData.append('transactionId', this.transactionId);

    // if (this.screenshot) {
    //   formData.append('screenshot', this.screenshot);
    // }
    const registrationData = this.formService.getRegistrationData();
    const registrationFile = this.formService.getRegistrationFile();

    formData.append('name', registrationData.name);
    formData.append('number', registrationData.number);
    formData.append('email', registrationData.email);
    formData.append('school', registrationData.school);
    formData.append('std', registrationData.std);
    formData.append('year', registrationData.year);
    formData.append('branch', registrationData.branch);
    formData.append('aadhar', registrationData.aadhar);
    formData.append('dateOfBirth', registrationData.dateOfBirth);
    formData.append('gender', registrationData.gender);
    formData.append('state', registrationData.state);
    formData.append('dist', registrationData.dist);
    formData.append('taluka', registrationData.taluka);
    formData.append('city', registrationData.city);
    formData.append('address', registrationData.address);
    formData.append('aadharadd', registrationData.aadharadd);
    formData.append('referralCode', registrationData.referralCode);

//referralCode
    if (registrationFile) {
      formData.append('file1', registrationFile);
    }

    this.http.post('https://cloud.vstechhorizon.com/applyscholarship', formData).subscribe(
      response => {
        // console.log('Payment details submitted successfully', response);
        this.clearFormOnReload();
        this.showPopup();

        // alert('Scholarship Registration Received.\nThank you for registering.\nWe have received your payment details.\n Your registration will be confirmed once we verify your transaction ID.\nOur team is reviewing your payment. \nThis process typically takes 2-3 business days. We will notify you via email once confirmed.\nThank you for Registering.');
        // window.location.href ='/';

        this.clearFormOnReload(); // Clear form on successful submission
      },
      error => {
        console.error('Error submitting payment details', error);
      }
    );
  }

  copyUPIId(): void {
    const textArea = document.createElement('textarea');
    textArea.value = this.upiId;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    alert('UPI ID copied to clipboard: ' + this.upiId);
  }

  showPopup() {
    const popupDialog = document.getElementById('popupDialog');
    if (popupDialog) {
      popupDialog.style.display = 'flex';
    }
  }

  closePopup() {
    const popupDialog = document.getElementById('popupDialog');
    if (popupDialog) {
      popupDialog.style.display = 'none';
      this.clearFormOnReload();
      this.router.navigate(['/']);
    }
  }



  payNow() {
    console.log(this.registrationData1.name ,
      this.registrationData1.email,
             this.registrationData1.number)
    const amount =999; // Amount in INR
    const currency = 'INR';

    this.paymentService.createOrder(amount, currency).subscribe(
      (order: any) => {

        // Assuming order.id is the Payment ID
        console.log(`Order created with Payment ID: ${order.id}`);
        this.paymentService.initiatePayment(order.id, amount,currency,this.registrationData1.name ,
       this.registrationData1.email,
              this.registrationData1.number , this.paymentResponseHandler.bind(this));
      },
      (error: any) => {
        console.error('Order creation failed', error);
      }
    );
  }

paymentResponseHandler(response: any) {
    // Assuming response contains transaction details
    // const transactionId = response.transactionId; // Example key for Transaction ID
    // console.log(`Payment successful with Transaction ID: ${transactionId}`);
    //   console.log('Payment successful', response);
    const paymentId = response.razorpay_payment_id;
    const orderId = response.razorpay_order_id;
    const signature = response.razorpay_signature;
      this.transactionId=response.razorpay_payment_id;
    console.log('Payment ID:', paymentId);
    console.log('Order ID:', orderId);
    console.log('Signature:', signature);
    // this.showPopup();
    this.submitPayment();
    // Handle the rest of the payment response, e.g., storing to backend, updating UI, etc.
    // this.saveTransactionDetails(response);
}

saveTransactionDetails(response: any) {
    // Implement saving the transaction details to your backend or local storage
    // this.paymentService.saveTransaction(response).subscribe(
    //   (result: any) => {
    //     console.log('Transaction details saved successfully', result);
    //   },
    //   (error: any) => {
    //     console.error('Failed to save transaction details', error);
    //   }
    // );
}
}

